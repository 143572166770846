import React from "react";
import { sendGA } from "utils";
import styles from "./main-prd-banner.module.scss";

interface Props {
  product: IProduct;
}

const MainProductBanner: React.FC<Props> = ({ product }) => {

  const onClickProduct = () => {
    sendGA("click_shopping_livebroadcast_function", "action", {
      button_name: "상품보기",
      item_id: product.seq,
      item_name: product.name,
      brand: product.brand,
      category1: product.bigName,
      category2: product.midName,
      category3: product.smallName,
      discount: product.discount,
      value: product.price,
      currency: "WON",
    });
  }
  return (
    <div className={styles.bottom__banner}>
      <a href={product.url} onClick={onClickProduct}>
        <img src={product.imagePath} alt="" />
        <div className={styles.bottom__banner__info}>
          <div className={styles.bottom__banner__name}>{product.name}</div>
          <div className={styles.bottom__banner__price}>
            {product.discountRate && (
              <span className={styles.bottom__banner__sale}>
                {product.discountRate}%
              </span>
            )}
            <span className={styles.bottom__banner__real}>
              {product.price.toLocaleString()}원
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default MainProductBanner;
