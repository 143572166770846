import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_REVERSE,
  MUTATION_INSERT,
  MUTATION_CANCEL,
} from "gqls/reverse.gql";
import NextBanner from "components/reservationNextBanner";
import PopupAlert from "components/popupAlert";
import styles from "./reservation.module.scss";
import Loading from "components/loading";
interface Props {
  liveContents: liveContents;
}

const Resevation: React.FC<Props> = ({ liveContents }) => {
  const params = useParams();

  const [contentSeq, setContentSeq] = useState<number>(Number(params.seq) || 1);
  const [isReserve, setIsReserve] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [authrorize, setAuthrorize] = useState<boolean>(false);

  const { data, error, loading } = useQuery(GET_REVERSE, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        businessContentSeq: contentSeq,
      },
    },
  });

  // 알림 신청
  const [requestReserve] = useMutation(MUTATION_INSERT, {
    onCompleted: () => {
      setIsReserve(true);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 알림 해제
  const [cancelReserve] = useMutation(MUTATION_CANCEL, {
    onCompleted: () => {
      setIsReserve(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (!data) return;

    // 로그인 됐을때
    setAuthrorize(true);

    setIsReserve(data.isRequested.ok);
  }, [data]);

  useEffect(() => {
    if (!error) return;

    // 로그인 안됐을때
    unauthorizedError();
  }, [error]);

  const onClickReserveBtn = () => {
    setIsModalOpen(true);

    if (isReserve) {
      // 현재 상태: 예약 O
      cancelReserve({
        variables: {
          input: {
            businessContentSeq: contentSeq,
          },
        },
      });
    } else {
      // 현재 상태: 예약 x
      requestReserve({
        variables: {
          input: {
            businessContentSeq: contentSeq,
          },
        },
      });
    }
  };

  const unauthorizedError = () => {
    setIsModalOpen(true);
    setAuthrorize(false);
  };

  const onClickModalConfirm = () => {
    setIsModalOpen(false);
  };

  const hasNextLive = liveContents?.nextTitle?.trim()?.length > 0 && liveContents?.nextThumbImage?.trim()?.length > 0;
  const nextLive: INextLiveData = {
    imgUrl: liveContents.nextThumbImage,
    title: liveContents.nextTitle,
    time: liveContents.nextDate,
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.contentsWrap}>
      <div>
        <div className={styles.contents__title}>
          <span>마미톡쇼핑</span>
          <img src="/images/live_ico.svg" alt="live_icon" />
        </div>
        <div className={styles.contents__img}>
          <div className={styles.imgbox}>
            <img src={liveContents.mainImage} alt="" />
          </div>
        </div>
        <div className={styles.contents__desc}>
          <p className={styles.subtit}>{liveContents.hashtag}</p>
          <p className={styles.tit}>{liveContents.title}</p>
          <p className={styles.desc}>{liveContents.contents}</p>
        </div>
        <div className={styles.contents__alert}>
          <button
            className={styles.btn__alert}
            onClick={onClickReserveBtn}
            style={{ background: isReserve ? "#D2D2D2" : "#FF5553" }}
          >
            <img src="/images/alert_ico.svg" alt="alert_icon" />
            <span>라이브 알림 {isReserve ? "해제" : "받기"}</span>
          </button>
        </div>
      </div>

      {/* 다음 라이브 */}
      {hasNextLive && <NextBanner data={nextLive} />}

      {/* 팝업 */}
      <PopupAlert
        isModalOpen={isModalOpen}
        onClickModalConfirm={onClickModalConfirm}
      >
        {authrorize ? (
          <React.Fragment>
            마미톡쇼핑 라이브 <br />
            알림
            <span>{isReserve ? "설정" : "해제"}</span>되었습니다.
          </React.Fragment>
        ) : (
          <React.Fragment>
            마미톡 최신 앱으로 이용 가능한 서비스입니다.
            <br />
            업데이트 후 이용해 주세요.
          </React.Fragment>
        )}
      </PopupAlert>
    </div>
  );
};

export default Resevation;
