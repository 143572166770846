import React, { useEffect, useState } from "react";
import styles from "./block.module.scss";
interface Props {}

const Block: React.FC<Props> = () => {
  const commerceEvent = (eventType: string) => {
    try {
      window.webkit.messageHandlers.commerceEvent.postMessage(eventType);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.closed_mall_wrapper}>
      <div className={styles.closed_mall_main_text_area}>
        <div className={styles.closed_mall_main_text}>프리미엄 마미톡쇼핑</div>
        <div className={styles.closed_mall_sub_text}>
          22년 9월 1일부터
          <br />
          마미톡 쇼핑을 이용할 수 있는 권한이 변경됩니다.
        </div>
        <img
          className={styles.closed_mall_image}
          src="/images/closedMallImage.png"
          alt="폐쇄몰 이미지"
        />
      </div>

      <div className={styles.closed_mall_info}>
        <div className={styles.closed_mall_info_barcode}>
          병원 바코드를 입력하거나 커뮤니티 활동 등 다양한 서비스를 통해
          <br />
          레벨을 올려 프리미엄 혜택을 경험해보세요!
        </div>
      </div>
      <div className={styles.closed_mall_btn_area}>
        <button
          className={styles.closed_mall_btn}
          onClick={() => commerceEvent("barcode")}
        >
          바코드 입력하기
        </button>
        <button
          className={styles.closed_mall_btn}
          onClick={() => {
            window.location.href = "mmtalk-kr-app://community/12/116953";
          }}
        >
          공지사항 보러가기
        </button>
      </div>
    </div>
  );
};

export default Block;
