import React, { useContext, useEffect, useState } from "react";
import socketIOClient, { Socket } from "socket.io-client";
import Reservation from "pages/reservation";
import OffAir from "pages/offAir";
import OnAir from "pages/onAir";
import { GET_LIVE } from "gqls/contents";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Helmet from "react-helmet";
import { sendGA } from "utils";
import { GET_USER } from "gqls/users";
import Loading from "components/loading";
import Block from "pages/block";
interface Props {}

const socketUrl: string = process.env.REACT_APP_SOCKET_URL || "";
const Main: React.FC<Props> = () => {
  const [liveData, setLiveData] = useState<liveContents | null>(null);
  const { seq } = useParams();
  const onAirStatus = ["READY", "START", "STOP"];
  const [socket, setSocket] = useState<Socket | null>(null);
  const [commentData, setCommentData] = useState<IComment[]>([]);
  const [user, setUser] = useState<chatObject | null>(null);

  useEffect(() => {
    const tempSocket = socketIOClient(socketUrl, {
      transports: ["websocket"],
      query: {
        showSeq: seq,
      },
    });

    setSocket(tempSocket);
  }, []);

  useEffect(() => {
    sendGA("view_shopping_livebroadcastdetail", "page", {
      page_name: "라이브 방송 상세",
    });
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("message", (message) => {
        setCommentData((prev) => [...prev, message]);
      });

      socket.on("messages", (messages) => {
        if (commentData.length === 0) {
          const parsedArray = messages.map((message: any) => ({
            nickname: message.nickname,
            userSeq: message.userSeq,
            message: message.message,
          }));
          setCommentData(parsedArray);
        }
      });
    }
  }, [socket]);

  const {
    data: userData,
    error: userError,
    loading,
  } = useQuery(GET_USER, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (userData) {
      setUser(userData.getUser.user);
    }
  }, [userData]);

  const { data: showData, error: contentsError } = useQuery(GET_LIVE, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        seq: Number(seq),
      },
    },
    pollInterval: 3000,
  });

  useEffect(() => {
    if (showData) {
      setLiveData(showData.getLive.live);

      if (showData.getLive.live.status === "START") {
        socket?.emit("join");
      }
    }
  }, [showData]);

  const emitMessage = (message: any) => {
    const chat = {
      userSeq: user?.seq,
      nickname: user?.nickname,
      message,
      sendedAt: Date.now(),
    };
    socket?.emit("message", chat);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div id="wrapper">
      {user && user.level >= 4 ? (
        liveData && (
          <React.Fragment>
            <Helmet>
              <title>[마미톡쇼핑 LIVE] {liveData.title}</title>
            </Helmet>
            {/* <Resevation liveContents={liveData} />   */}
            {/* 알림 예약 뷰 */}
            {liveData.status === "CREATED" && (
              <Reservation liveContents={liveData} />
            )}
            {/* 라이브 진행 뷰 */}
            {onAirStatus.indexOf(liveData.status) !== -1 && (
              <OnAir
                liveContents={liveData}
                commentData={commentData}
                emitMessage={emitMessage}
                user={user}
              />
            )}
            {/* 라이브 종료 후 다시보기 뷰 */}
            {liveData.status === "REPLAY" && <OffAir liveContents={liveData} />}
          </React.Fragment>
        )
      ) : (
        <Block />
      )}
    </div>
  );
};

export default Main;
