import { gql } from '@apollo/client';

export const GET_REVERSE = gql`
  query isRequested($input: PushInput!){
    isRequested(input: $input){
      ok,
      error,
      push {
        seq,
        businessContentSeq,
        userSeq,
      }
    }
  } 
`;

export const MUTATION_INSERT = gql`
  mutation InsertPush($input: InsertPushInput!){
    insertPush(input: $input){
      ok,
      error,
      push {
        seq,
        businessContentSeq,
        userSeq,
      }
    }
  } 
`;

export const MUTATION_CANCEL = gql`
  mutation CancelPush($input: CancelPushInput!){
    cancelPush(input: $input){
      ok,
      error,
      push {
        seq,
        businessContentSeq,
        userSeq,
      }
    }
  } 
`;
