import React from "react";
import moment from "moment";
// import "moment/locale/ko";
import styles from "./next-banner.module.scss";
interface Props {
  data: INextLiveData;
}

const day = ["일", "월", "화", "수", "목", "금", "토"];

const dateFormatter = (date: any) => {
  date = moment(date).utc();
  console.log(date);
  console.log(date.format("h"));
  if (date) {
    return `${date.format("MM월DD일")}(${day[+date.format("E")]}) ${
      date.format("HH") > 11 ? "오후" : "오전"
    } ${date.format("HH")}시`;
  }
};

const NextBanner: React.FC<Props> = ({ data }) => {
  // moment.locale("ko");
  return (
    <div className={styles.contents__banner}>
      <img src={data.imgUrl} alt="" />
      <div className={styles.banner__title}>
        <p>[다음 라이브] {data.title}</p>
        <span>
          {dateFormatter(data.time)}
          {/* {moment(data.time).format("MMM Do")}({moment(data.time).format("dd")}){" "}
          {moment(data.time).format("a h")}시 */}
        </span>
      </div>
    </div>
  );
};

export default NextBanner;
