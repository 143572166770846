export const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i) == null ? false : true;
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null
        ? false
        : true;
    },
    any: function () {
      return isMobile.Android() || isMobile.iOS();
    },
  };
  
  export const sendGA = (eventName: string | null, eventType: "page" | "action", params?: any) => {
    console.log(eventName, eventType, params)
    if (isMobile.Android() || isMobile.iOS()) {
      try {
        window.webkit.messageHandlers.sendGA.postMessage(
          JSON.stringify({
            eventName,
            eventType,
            ...(params && { params }),
          }),
        );
      } catch (error) {
        console.log(error);
      }
    }
  };