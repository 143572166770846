import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser {
    getUser {
      ok
      user {
        seq
        nickname
        level
      }
    }
  } 
`;