import PopupProduct from 'components/popupProduct'
import React, { useEffect, useState } from 'react'
import styles from './onair-prd-popup.module.scss'

interface Props {
  isItemOpen: boolean;
  setIsItemOpen: React.Dispatch<React.SetStateAction<boolean>>;
  products: IProduct[];
}

const OnAirPrdPopup: React.FC<Props> = ({ 
  isItemOpen,
  setIsItemOpen,
  products,
}) => {
  const [isOpen, setIsOpen] = useState(isItemOpen || false);

  useEffect(() => {
    setIsOpen(isItemOpen);
  }, [isItemOpen])

  return (
    <div className={styles.play__popup}>
      <div className={isOpen? styles.popup__actv : ''}> 
        <div className={styles.play__popup__dim}></div>
        <div className={styles.play__popup__contents}>
          <div className={styles.popup__product__tit}>
            라이브 한정 상품 <span>⏰</span>
            <button onClick={() => setIsItemOpen(false)}></button>
          </div>
          <ul className={styles.popup__products__list}>
            {products.map((product: IProduct, i: number) => <PopupProduct product={product} key={i} />)}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default OnAirPrdPopup
