import CommentsBox from 'components/commentsBox'
import DataAlert from 'components/dataAlert'
import MainProductBanner from 'components/mainProductBanner'
import OnAirPrdPopup from 'components/onAirPrdPopup'
import TopInfo from 'components/onAirTopInfo'
import PlayButton from 'components/playButton'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import styles from './off-air.module.scss'
import styled from 'styled-components';
interface Props {
  liveContents: liveContents;
  commentData?: IComment[];
}
interface VideoContainer {
  backImage: string;
}

const VideoWrapDiv = styled.div<VideoContainer>`
  background-image: ${(props) => (props.backImage ? `url(${props.backImage})` : '')};
`;

const OffAir: React.FC<Props> = ({ liveContents, commentData }) => {
  const [isItemOpen, setIsItemOpen] = useState(false);
  const [mutedState, setMutedState] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [controllerHide, setControllerHide] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [time, setTime] = useState('');
  const [speed, setSpeed] = useState(1)

  const [url, setUrl] = useState(liveContents.replayUrl);

  const videoRef = useRef<any>();
  
  const topInfoData = {
    title: liveContents.title,
    tag: liveContents.hashtag,
  }

  useEffect(() => {
    setTime(timeFormat((duration * (1 - played)).toFixed()))
  }, [played]);

  useEffect(() => {
    if (url !== "") setPlaying(true)
  }, [url]);

  const onClickLiveItem = () => {
    setIsItemOpen(true);
  }

  const handleSeekMouseDown = () => {
    setSeeking(true)
  }

  const handleSeekChange = (e: any) => {
    setPlayed(e.target.value);
    videoRef.current.player.seekTo(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = () => {
    setSeeking(false);
  }

  const onDuration = (duration: number) => {
    setTime(timeFormat((duration * (1 - played)).toFixed()))
    setDuration(duration);
  }

  const handleProgress = (state: any) => {
    setPlayed(state.played);
  }

  const timeFormat = (seconds: string) => {
    const date = new Date(Number(seconds) * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = ('0' + date.getUTCSeconds()).slice(-2)
    if (hh) {
      return `${hh}:${('0' + mm).slice(-2)}:${ss}`
    }
    return `${mm}:${ss}`
  }
  
  const onClickVideo = () => {
    setControllerHide(!controllerHide);
  }

  const onClickSpeed = () => {
    let playbackRate = (speed === 2 ? 0.5 : speed) + 0.5

    setSpeed(playbackRate);
  }

  return (
    <div className={styles.contentsWrap}>
      <div 
        className={`${styles.playWrap} 
        ${controllerHide? styles.controller__hide : ''}`}
      >
        <DataAlert />
        <TopInfo 
          topInfoData={topInfoData} 
          mutedState={mutedState} 
          setMutedState={setMutedState} 
        />
        <PlayButton 
          playing={playing}
          setPlaying={setPlaying}
        />
        <div className={styles.videoWrap}>
          <VideoWrapDiv backImage={liveContents.backImage} className={styles.videoBox} onClick={onClickVideo}>
            <ReactPlayer 
              ref={videoRef}
              url={url}
              width='100%'
              height='100%'
              playing={playing}
              muted={mutedState}
              playsinline={true}
              onDuration={onDuration}
              progressInterval={1000}
              onProgress={handleProgress}
              playbackRate={speed}
            />
          </VideoWrapDiv>
          <div className={styles.controls}>
            <input
              type='range' min={0} max={0.999999} step='any'
              value={played}
              onMouseDown={handleSeekMouseDown}
              onTouchStart={handleSeekMouseDown}
              onChange={handleSeekChange}
              onTouchMove={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
              onTouchEnd={handleSeekMouseUp}
            />
            <span className={styles.progress__time}>{time}</span>
          </div>
        </div>
        <div className={styles.play__bottom}>
          {/* <CommentsBox commentData={commentData} /> */}
          <MainProductBanner product={liveContents.products[0]} />
          <div className={styles.bottom__btn}>
            <button className={styles.bottom__btn__speed} onClick={onClickSpeed}>{speed}x</button>
            <button className={styles.bottom__btn__item} onClick={onClickLiveItem}>
              <span>{liveContents.products.length}</span>
            </button>
          </div>
        </div>
        <OnAirPrdPopup 
          isItemOpen={isItemOpen} 
          setIsItemOpen={setIsItemOpen}
          products={liveContents.products}
        />

        <div className={styles.notice__content}>
          <div>라이브가 종료되었습니다.</div>
          <div>다시보기를 준비중입니다.</div>
        </div>
      </div>
    </div>
  )
}

export default OffAir
