import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import ReactGA from "react-ga4";
import "./App.css";
import { GET_USER } from "gqls/users";
import { useQuery } from "@apollo/client";
import Host from "pages/host";
import Main from "pages/main";
import Block from "pages/block";
import Loading from "components/loading";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-E808BLNE1D");
    ReactGA.send("pageview");
  }, []);

  return (
    <Routes>
      <Route path="/host/:seq" element={<Host />} />
      <Route path="/:seq" element={<Main />} />
      {/* <Route
        path="/:seq"
        element={user && user.level >= 4 ? <Main user={user} /> : <Block />}
      /> */}
    </Routes>
  );
}

export default App;
