import React from "react";
import styles from "./popup-alert.module.scss";

interface Props {
  isModalOpen: boolean;
  onClickModalConfirm: () => void;
  children: React.ReactNode;
}

const PopupAlert: React.FC<Props> = ({
  isModalOpen,
  onClickModalConfirm,
  children,
}) => {
  return (
    <div
      className={`${styles.contents__modal} ${isModalOpen ? styles.on : ""}`}
    >
      <div className={styles.alert}>
        <p>{children}</p>
        <button onClick={onClickModalConfirm}>확인</button>
      </div>
    </div>
  );
};

export default PopupAlert;
