import React, { useEffect, useState } from 'react'
import styles from './sound-button.module.scss'

interface Props{
  mutedState: boolean;
  setMutedState: Function;
}

const SoundButton: React.FC<Props> = ({ mutedState, setMutedState }) => {
  const [isMuted, setIsMuted] = useState(mutedState);
  
  useEffect(() => {
    setIsMuted(mutedState);
  }, [mutedState])
  return (
    <button 
      className={styles.sound__button}
      onClick={() => setMutedState(false)}
      style={{display: isMuted ? '' : 'none'}}
    >
      <i></i>소리를 켜려면 누르세요
    </button>
  )
}

export default SoundButton
