import React, { useEffect, useRef, useState } from "react";
import DataAlert from "components/dataAlert";
import MainProductBanner from "components/mainProductBanner";
import styles from "./on-air.module.scss";
import TopInfo from "components/onAirTopInfo";
import CommentsBox from "components/commentsBox";
import OnAirPrdPopup from "components/onAirPrdPopup";
import ReactPlayer from "react-player";
import PlayButton from "components/playButton";
import NoticeEnd from "components/noticeEnd";
import styled from "styled-components";
import PopupAlert from "components/popupAlert";
import { sendGA } from "utils";

interface Props {
  liveContents: liveContents;
  commentData?: IComment[];
  emitMessage: (message: any) => void;
  user: any;
}
interface VideoContainer {
  backImage: string;
}

const VideoWrapDiv = styled.div<VideoContainer>`
  background-image: ${(props) =>
    props.backImage ? `url(${props.backImage})` : ""};
`;

const OnAir: React.FC<Props> = ({
  liveContents,
  commentData,
  emitMessage,
  user,
}) => {
  const [isItemOpen, setIsItemOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [mutedState, setMutedState] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [popupComment, setPopupComment] = useState("");

  const [playing, setPlaying] = useState(
    liveContents.status === "STOP" ? true : false
  );
  const [url, setUrl] = useState(liveContents.liveUrl);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (url !== "") setPlaying(true);
    setUrl(liveContents.liveUrl);
  }, [liveContents]);

  const topInfoData = {
    title: liveContents.title,
    tag: liveContents.hashtag,
  };

  const onClickLiveItem = () => {
    setIsItemOpen(true);
  };

  const onClickModalConfirm = () => {
    setIsModalOpen(false);
  };

  const onClickCommentBtn = () => {
    if (!isCommentOpen) onFocus();
    sendGA("click_shopping_livebroadcast_function", "action", {
      button_name: "댓글",
    });
    setIsCommentOpen(!isCommentOpen);
  };

  const onSubmit = () => {
    if (!user) {
      setPopupComment(
        `마미톡 앱 회원만 가능합니다.\n마미톡 앱을 통해 접속해주세요.`
      );
      setIsModalOpen(true);
      return;
    }
    if (!inputRef?.current?.value) {
      setPopupComment(
        `빈 댓글은 전송할 수 없습니다.\n댓글 입력 후 전송해주세요.`
      );
      setIsModalOpen(true);
      return;
    }
    emitMessage(inputRef?.current?.value);
    setInputVal("");
  };

  const onFocus = () => {
    inputRef?.current?.focus();
  };

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") onSubmit();
  };

  return (
    <div className={styles.contentsWrap}>
      <div className={styles.playWrap}>
        <div>
          <DataAlert />
          <TopInfo
            topInfoData={topInfoData}
            mutedState={mutedState}
            setMutedState={setMutedState}
          />
          <PlayButton playing={playing} setPlaying={setPlaying} />
          {liveContents.status === "STOP" && <NoticeEnd />}
        </div>
        <VideoWrapDiv
          backImage={liveContents.backImage}
          className={styles.videoWrap}
        >
          <ReactPlayer
            url={url}
            muted={mutedState}
            autoPlay={true}
            playsinline={true}
            playing={playing}
            width="100%"
            height="100%"
          />
        </VideoWrapDiv>
        <div
          className={`${styles.play__bottom} 
          ${isCommentOpen ? styles.comment__actv : ""}`}
        >
          {/* 댓글리스트 */}
          <CommentsBox commentData={commentData} />
          {!isCommentOpen && (
            <MainProductBanner product={liveContents.products[0]} />
          )}
          <div className={styles.bottom__input}>
            <div>
              <input
                type="text"
                ref={inputRef}
                className={styles.input}
                maxLength={100}
                placeholder="실시간 채팅에 참여하세요."
                autoComplete="off"
                value={inputVal}
                onChange={(e) => {
                  setInputVal(e.currentTarget.value);
                }}
                onFocus={onFocus}
                onKeyPress={keyUpHandler}
              />
              <input
                type="button"
                className={styles.submit}
                onClick={onSubmit}
              />
            </div>
          </div>
          <div className={styles.bottom__btn}>
            <button
              className={styles.bottom__btn__comment}
              onClick={onClickCommentBtn}
            ></button>
            <button
              className={styles.bottom__btn__item}
              onClick={onClickLiveItem}
            >
              <span>{liveContents.products.length}</span>
            </button>
          </div>
        </div>
        <OnAirPrdPopup
          isItemOpen={isItemOpen}
          setIsItemOpen={setIsItemOpen}
          products={liveContents.products}
        />
      </div>
      <PopupAlert
        isModalOpen={isModalOpen}
        onClickModalConfirm={onClickModalConfirm}
      >
        <React.Fragment>{popupComment}</React.Fragment>
      </PopupAlert>
    </div>
  );
};

export default OnAir;
