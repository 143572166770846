import React, { useEffect, useRef, useState } from "react";
import styles from "./comments-box.module.scss";

interface Props {
  commentData: any;
}

const CommentsBox: React.FC<Props> = ({ commentData }) => {
  const [isMore, setIsMore] = useState(false);
  const [isMoveBtnShow, setIsMoveBtnShow] = useState(false);

  const commentWrapRef = useRef<any>();
  const commentInnerRef = useRef<any>();

  const commentGenerateCode = commentData.map(
    (comment: IComment, i: number) => (
      <div className={styles.bottom__comment} key={i}>
        <strong>{comment.nickname}</strong>
        <span>{comment.message}</span>
      </div>
    )
  );

  const commentAutoScorll = () => {
    commentWrapRef.current.scrollTo({
      top: commentInnerRef.current.clientHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    commentAutoScorll();
  }, [commentData]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const space = commentWrapRef.current.clientHeight;

    if (
      commentWrapRef.current.scrollTop + space + 30 <
      commentInnerRef.current.clientHeight
    ) {
      setIsMoveBtnShow(true);
      return;
    }
    setIsMoveBtnShow(false);
  };

  const onClickComments = () => {
    setIsMore(!isMore);
  };

  return (
    <div
      className={`
      ${styles.bottom__comments__box}
      ${isMoveBtnShow ? styles.comment__scroll : ""}
    `}
    >
      <button onClick={commentAutoScorll}>최신 채팅으로 이동</button>
      <div
        className={styles.bottom__comments}
        style={{ height: isMore ? "210px" : "170px" }}
        ref={commentWrapRef}
        onClick={onClickComments}
        onScroll={onScroll}
      >
        <div className={styles.bottom__comments_inner} ref={commentInnerRef}>
          {/* <!-- 댓글 리스트 --> */}
          {commentGenerateCode}
        </div>
      </div>
    </div>
  );
};

export default CommentsBox;
