import React, { useState } from 'react'
import styles from './data-alert.module.scss'

function DataAlert() {
  const [dataAlertState, setDataAlertState] = useState(true);

  return (
    <div 
      className={`
        ${styles.warning__popup}
        ${dataAlertState ? '' : styles.hide}
      `} 
    >
      <div><i></i><span>3G/LTE 환경에서는 데이터 사용료가 발생할 수 있습니다.</span></div>
      <button onClick={() => setDataAlertState(false)}></button>
    </div>
  )
}

export default DataAlert
