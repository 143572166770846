import { gql } from '@apollo/client';

export const GET_LIVE = gql`
  query GetLive($input: LiveInput!){
    getLive(input: $input){
      ok,
      error,
      live {
        seq,
        hashtag,
        title,
        contents,
        status,
        liveDate,
        mainImage,
        nextThumbImage
        nextDate,
        nextTitle,
        backImage,
        mainProduct,
        products{
          seq,
          name,
          brand,
          price,
          imagePath,
          bigName,
          midName,
          smallName,
          url,
          discount,
          discountRate,
          dir,
          createdAt
        },
        createdAt,
        replayUrl,
        liveUrl,
      }
    }
  } 
`;
