import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  position: absolute;
  border: none;
  outline: none;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  div:nth-child(1) {
    margin-bottom: 15px;
  }
  div:nth-child(2) {
    padding: 6px 17px 5px 16px;
    background-color: #fff;
    border-radius: 20px;
    color: #222;
  }
`;

function NoticeEnd() {
  return (
    <StyledButton>
      <div>라이브가 종료되었습니다.</div>
      <div>다시보기를 준비중입니다.</div>
    </StyledButton>
  );
}

export default NoticeEnd;
