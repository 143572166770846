import { removeDirectivesFromDocument } from "@apollo/client/utilities";
import React from "react";
import { sendGA } from "utils";
import styles from "./popup-product.module.scss";

interface Props {
  product: IProduct;
}

const PopupProduct: React.FC<Props> = ({ product }) => {
  const onClickProduct = () => {
    sendGA("click_shopping_livebroadcast_function", "action", {
      button_name: "상품보기",
      item_id: product.seq,
      item_name: product.name,
      brand: product.brand,
      category1: product.bigName,
      category2: product.midName,
      category3: product.smallName,
      discount: product.discount,
      value: product.price,
      currency: "WON",
    });
  };
  return (
    <li className={styles.popup__product}>
      <a href={product.url} onClick={onClickProduct}>
        <img src={product.imagePath} alt="" />
        <div className={styles.popup__product__price}>
          {product.discountRate && (
            <span className={styles.price__sale}>{product.discountRate}%</span>
          )}
          <span className={styles.price__real}>
            {product.price.toLocaleString()}원
          </span>
        </div>
        <div className={styles.popup__product__name}>{product.name}</div>
      </a>
    </li>
  );
};

export default PopupProduct;
