import CommentsBox from "components/commentsBox";
import TopInfo from "components/onAirTopInfo";
import PlayButton from "components/playButton";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import styles from "./host.module.scss";
import styled from "styled-components";
import { GET_LIVE } from "gqls/contents";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import socketIOClient, { Socket } from "socket.io-client";
import { Helmet } from "react-helmet";
interface Props {}
interface VideoContainer {
  backImage?: string;
}

const VideoWrapDiv = styled.div<VideoContainer>`
  background-image: ${(props) =>
    props.backImage ? `url(${props.backImage})` : ""};
`;

const socketUrl: string = process.env.REACT_APP_SOCKET_URL || "";

const Host: React.FC<Props> = () => {
  const [liveData, setLiveData] = useState<liveContents | null>(null);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [mutedState, setMutedState] = useState(true);
  const { seq } = useParams();
  const [playing, setPlaying] = useState(false);
  const [url, setUrl] = useState("");
  const [commentData, setCommentData] = useState<IComment[]>([]);
  const [userCnt, setUserCnt] = useState(0);
  const [viewUserCnt, setViewUserCnt] = useState(false);
  const commentWrapRef = useRef<any>();
  const commentInnerRef = useRef<any>();
  const [verticalFlag, setVertical] = useState(false);

  useEffect(() => {
    const tempSocket = socketIOClient(socketUrl, {
      transports: ["websocket"],
      auth: {
        hostToken: "awefawefijawoiefjawoief",
      },
      query: {
        showSeq: seq,
      },
    });

    setSocket(tempSocket);
  }, []);

  const { data: showData, error: contentsError } = useQuery(GET_LIVE, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        seq: Number(seq),
      },
    },
  });

  const commentAutoScorll = () => {
    commentWrapRef.current.scrollTo({
      top: commentInnerRef.current.clientHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    commentAutoScorll();
  }, [commentData]);
  // useEffect(() => {
  //   setUrl("https://media.w3.org/2010/05/sintel/trailer.mp")
  // }, []);

  useEffect(() => {
    if (url !== "") setPlaying(true);
  }, [url]);

  useEffect(() => {
    if (!showData) return;
    setLiveData(showData.getLive.live);

    if (showData.getLive.live.status === "START") {
      socket?.emit("join");
    }
    setUrl(showData.getLive.live.liveUrl);
  }, [showData]);

  useEffect(() => {
    if (socket) {
      socket.on("host_message", (message) => {
        setCommentData((prev) => [...prev, message]);
      });

      socket.on("messages", (messages) => {
        if (commentData.length === 0) {
          const parsedArray = messages.map((message: any) => ({
            showSeq: message.showSeq,
            nickname: message.nickname,
            userSeq: message.userSeq,
            message: message.message,
          }));
          setCommentData(parsedArray);
        }
      });

      socket.on("update_connect_count", function (cnt) {
        setUserCnt(cnt);
      });
    }
  }, [socket]);

  const userCntClick = () => {
    setViewUserCnt((prev) => !prev);
  };
  const topInfoData = {
    title: liveData?.title,
    tag: liveData?.hashtag,
  };

  const commentGenerateCode = commentData.map(
    (comment: IComment, i: number) => (
      <div className={styles.bottom__comment} key={i}>
        <strong
          onClick={() => {
            if (
              window.confirm(
                `${comment.nickname}(${comment.userSeq}) 유저를 차단하시겠습니까?`
              )
            ) {
              socket?.emit("user.block", {
                showSeq: comment.showSeq,
                userSeq: comment.userSeq,
              });
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            if (
              window.confirm(
                `${comment.nickname}(${comment.userSeq}) 유저를 차단해제하시겠습니까?`
              )
            ) {
              socket?.emit("user.unblock", {
                showSeq: comment.showSeq,
                userSeq: comment.userSeq,
              });
            }
          }}
        >
          {comment.nickname}
          <i> ({comment.userSeq})</i>
        </strong>
        <span>{comment.message}</span>
      </div>
    )
  );

  const handleVerticalView = () => {
    setVertical((prev) => !prev);
  };

  return (
    <>
      <div className={`${styles.host} ${verticalFlag ? styles.vertical : ""}`}>
        <Helmet>
          <title>[마미톡쇼핑 LIVE] {liveData ? liveData?.title : ""}</title>
        </Helmet>
        <button className={styles.vertical_button} onClick={handleVerticalView}>
          {verticalFlag ? "VERTICAL" : "HORIZONTAL"}
        </button>
        <div className={styles.videoCont}>
          <TopInfo
            topInfoData={topInfoData}
            mutedState={mutedState}
            setMutedState={setMutedState}
          />
          <VideoWrapDiv
            backImage={liveData?.backImage}
            className={styles.videoWrap}
          >
            <ReactPlayer
              url={url}
              muted={mutedState}
              autoPlay={true}
              playsinline={true}
              playing={playing}
              width="100%"
              height="100%"
            />
          </VideoWrapDiv>
        </div>
        <div className={styles.commentCont} ref={commentWrapRef}>
          <div className={styles.hiddenArea} onClick={userCntClick}></div>
          <div
            className={styles.user__count}
            style={{ display: viewUserCnt ? "" : "none" }}
          >
            현재 접속자 수 :
            <span className={styles.user__connect__count}>{userCnt}</span>
          </div>
          {/* 댓글리스트 */}
          <div className={styles.bottom__comments__box} ref={commentInnerRef}>
            {/* <!-- 댓글 리스트 --> */}
            {commentGenerateCode}
          </div>
        </div>
      </div>
    </>
  );
};

export default Host;
