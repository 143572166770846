import React from 'react'
import styles from './play-button.module.scss'

interface Props{
  playing: boolean;
  setPlaying: Function;
}
const PlayButton: React.FC<Props> = ({ 
  playing,
  setPlaying
}) => {
  return (
    <div 
      className={styles.play__btn}
      style={{display: playing === true ? 'none' : ''}}
    >
      <button 
        className={styles.play__btn__button}
        onClick={() => {setPlaying(true)}}
      >
        <span></span>
      </button>
    </div>
  )
}

export default PlayButton
