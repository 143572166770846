import React from "react";
import ReactDOM from "react-dom/client";
import queryString from "query-string";
import { BrowserRouter } from "react-router-dom";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const devUrl = process.env.REACT_APP_API_SERVER_URL;
console.log('@@@@devUrl : ', devUrl)
const httpLink = createHttpLink({
  uri: `${devUrl}/graphql`,
});
const parsedQueryString = queryString.parse(window.location.search);
const authLink = setContext((_, { headers }) => {
  const accessToken = parsedQueryString.accessToken || "";
  return {
    headers: {
      ...headers,
      "x-auth-token": accessToken,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>
);
