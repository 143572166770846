import React from 'react'
import styles from './onair-top-info.module.scss'
import SoundButton from 'components/soundButton'

interface Props{
  topInfoData?: ITopData;
  mutedState: boolean;
  setMutedState: Function;
}
interface ITopData{
  title?: string;
  tag?: string;
}

const TopInfo: React.FC<Props> = ({ 
  topInfoData, 
  mutedState,
  setMutedState, 
}) => {
  return (
    <div className={styles.play__top} >
      <div className={styles.play__title}>
        <span>마미톡쇼핑</span>
        <img src="https://static.app.mmtalk.kr/business-contents/live-common/images/live_ico.svg" alt="" />
      </div>
      <div className={styles.play__desc}>
        <p className={styles.play__desc__subtit}>{topInfoData?.tag}</p>
        <p className={styles.play__desc__tit}>{topInfoData?.title}</p>
      </div>
      <SoundButton mutedState={mutedState} setMutedState={setMutedState} />
    </div>
  )
}

export default TopInfo
